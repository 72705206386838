import React, { useEffect, useState } from 'react';
import { UserApi } from '../ApiConfig';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import HealthDataList from './HealthData';
import AccountList from './AccountList';
import HealthDataDiagnosticsList from './HealthDataDiagnosticsList';
import IntelliproveDataDiagnosticsList from './IntelliproveDataDiagnosticsList';
import UserProfileForm from './UserProfileForm';
import ActivityPoolList from './ActivityPoolList';

const UserForm = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const { hash } = useLocation();
    const [user, setUser] = useState({
        email: '',
        phone: '',
        signUpDate: '',
        role: '',
        id: ''
    });

    useEffect(() => {
        if (userId) {
            const fetchUser = async () => {
                const response = await UserApi.get(`/users/${userId}`);
                setUser(response.data);
                setShowForm(true);
            };
            fetchUser();
        }
        else {
            //Set user defaults
            const current = new Date();
            const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
            user.signUpDate = date;
            setShowForm(true);
        }
    }, [userId]);

    useEffect(() => {
        if (hash === "#userdetails") setTabIndex(0);
        else if (hash === "#profile") setTabIndex(1);
        else if (hash === "#account") setTabIndex(2);
        else if (hash === "#healthdata") setTabIndex(3);
        else if (hash === "#diagnostics") setTabIndex(4);
        else if (hash === "#intelliprove") setTabIndex(5);
        else setTabIndex(0);
    }, [hash]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    //Handles Updates to User - There are No Creates Allowed
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (userId) {
            await UserApi.put(`/users/${userId}`, user);
        }
        navigate(-1);
    };

    const handleTabChange = (e) => {
        setTabIndex(Number(e.target.value));
    }

    return (
        !showForm
            ?
            <div>Please Wait ... {showForm}</div>
            :
            <div>
                <div>
                    <div class="sm:hidden">
                        <label for="tabs" class="sr-only">Select a tab</label>
                        <select id="tabs" onChange={handleTabChange} value={tabIndex} name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                            <option value="0">User Details</option>
                            <option value="1">Profile</option>
                            <option value="2">Account</option>
                            <option value="3">Health Data</option>
                            <option value="4">Health Data Diagnostics</option>
                            <option value="5">Intelliprove Diagnostics</option>
                            <option value="6">Pools</option>
                        </select>
                    </div>
                    <div class="hidden sm:block">
                        <div class="border-b border-gray-200">
                            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                                <Link onClick={() => setTabIndex(0)} to="#userdetails" class={tabIndex === 0 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>User Details</Link>
                                <Link onClick={() => setTabIndex(1)} to="#profile" class={tabIndex === 1 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Profile</Link>
                                <Link onClick={() => setTabIndex(2)} to="#account" class={tabIndex === 2 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Account</Link>
                                <Link onClick={() => setTabIndex(3)} to="#healthdata" class={tabIndex === 3 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Health Data</Link>
                                <Link onClick={() => setTabIndex(4)} to="#diagnostics" class={tabIndex === 4 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Diagnostics</Link>
                                <Link onClick={() => setTabIndex(5)} to="#intelliprove" class={tabIndex === 5 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Intelliprove</Link>
                                <Link onClick={() => setTabIndex(6)} to="#pools" class={tabIndex === 6 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Pools</Link>
                            </nav>
                        </div>
                    </div>
                </div>



                <section id="content">
                    {tabIndex === 0
                        ?
                        <form onSubmit={handleSubmit} class="mt-4">

                            <section id="userDetailsTab">
                                <div class="mt-4">
                                    <div class="space-y-12">
                                        <div class="border-b border-gray-900/10 pb-12">
                                            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                                <div class="sm:col-span-6 bg-black text-white px-2">
                                                    <h2 class="text-base font-semibold leading-7">User Details ({user.id}) - Created {user.signUpDate}</h2>
                                                </div>

                                                <div class="sm:col-span-3">
                                                    <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                                                    <div class="mt-2">
                                                        <input type="text" name="email" value={user.email} onChange={handleChange} placeholder="Email" id="first-name" autocomplete="given-name" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                                    </div>
                                                </div>

                                                <div class="sm:col-span-3">
                                                    <label for="phone" class="block text-sm font-medium leading-6 text-gray-900">Phone</label>
                                                    <div class="mt-2">
                                                        <input name="phone" value={user.phone} onChange={handleChange} placeholder="Phone" id="phone" autocomplete="phone" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                                    </div>
                                                </div>
                                                <div class="sm:col-span-3">
                                                    <label for="role" class="block text-sm font-medium leading-6 text-gray-900">Role</label>
                                                    {
                                                        user.role === "SPONSOR"
                                                            ?
                                                            <span>Sponsor</span>
                                                            :
                                                            <div class="mt-2">
                                                                <select id="role" name="role" value={user.role} onChange={handleChange} placeholder="role" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                                    <option>USER</option>
                                                                    <option>ADMIN</option>
                                                                </select>
                                                            </div>
                                                    }
                                                </div>

                                                <div class="sm:col-span-6 bg-black text-white px-2">
                                                    <h2 class="text-base font-semibold leading-7">WeFitter</h2>
                                                </div>                                                

                                                <div class="sm:col-span-6">
                                                      <div class="px-4 py-5 sm:p-6">
                                                        <h3 class="text-base font-semibold leading-7 text-gray-900 text-xs mt-2">WeFitter PublicId: {user.weFitterPublicId}</h3>
                                                        <span class="text-base font-semibold leading-7 text-gray-900 text-xs mt-2">WeFitter Profile Token: </span>
                                                        <textarea class="text-base font-semibold leading-7 text-gray-900 text-xs mt-2 w-full p-2" readOnly value={user.weFitterProfileToken}>
                                                        </textarea>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div class="mt-6 flex items-center justify-end gap-x-6">
                                        <Link to="/users" class="text-sm font-semibold leading-6 text-gray-900">Cancel</Link>
                                        <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                                    </div>
                                </div>
                            </section>
                        </form>

                        :
                        tabIndex === 1
                            ?
                            <UserProfileForm userId={userId} />
                            :
                            tabIndex === 2
                                ?
                                <AccountList user_id={userId} />
                                :
                                tabIndex === 3
                                    ?
                                    <HealthDataList userId={user.id} />
                                    :
                                    tabIndex === 4
                                        ?
                                        <HealthDataDiagnosticsList userId={user.id} />
                                        :
                                        tabIndex === 5
                                            ?
                                            <IntelliproveDataDiagnosticsList userId={user.id} />
                                            :
                                            <ActivityPoolList user_id={user.id} />
                    }
                </section>

            </div>


    );
};

export default UserForm;