import React, { useEffect, useStatus } from 'react';

const ActivityPoolStatusButton = (props) => {

    var activityPool = props.activityPool;
    var activityPoolStatus = activityPool ? activityPool.activityPoolStatus : "UNKNOWN";
    var setActivityPoolStatus = props.setActivityPoolStatus;

    return (
        activityPoolStatus === "DRAFT"
        ?
        <button type="button" onClick={() => setActivityPoolStatus("REVIEW")} class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Send for Review</button>
        :
        activityPoolStatus === "REVIEW"
        ?
        <button type="button" onClick={() => setActivityPoolStatus("PUBLISHED")} class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Publish</button>
        :
        activityPoolStatus === "XXPUBLISHED"
        ?
        <button type="button" onClick={() => setActivityPoolStatus("OPEN")} class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Force Open</button>
        :
        <></>
    );
};

export default ActivityPoolStatusButton;