import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DisplayProgressMessage from './DisplayProgressMessage';
import DisplayErrorMessages from './DisplayErrorMessages';
import axios from "axios";
import { UserApi, UserProfileApi } from '../ApiConfig';

const DeveloperTools = () => {
    const [progressMessage, setProgressMessage] = useState();
    const [errorMessages, setErrorMessages] = useState([]);

    const addErrorMessage = (newError) => { setErrorMessages([...errorMessages, newError]) };

    useEffect(() => {
    }, []);

    const exportUsers = async (e) => {
        if (e) e.preventDefault();
        setErrorMessages([]);
        try {
            setProgressMessage("Checking Local API on port 8000 ...");
            var response = await axios.get('http://localhost:8000/api-status')
            if (response.status != 204) {
                setProgressMessage("No API found on port 8000");
                return;
            }
            else {
                setProgressMessage("Fetching Users from Healthblocks API ...");
                var userResponse = await UserApi.get("/users/");
                if (userResponse.status !== 200) {
                    setProgressMessage(`Error Calling Healthblocks User API. - REsponse Status ${userResponse.status}`);
                    return;
                }
                var users = userResponse.data.data;
                setProgressMessage(`Found ${users.length} Users ... Uploading to Local API port 8000 ...`);
                for (const user of users) {
                    setProgressMessage(`Uploading user ${user.email} ...`);
                    var newUser = {
                        email: user.email,
                        phone: user.phone,
                        signUpDate: user.signUpDate,
                        role: user.role
                    }
                    var addResponse = await axios.put(`http://localhost:8000/users/${user.id}`, newUser, { headers: { 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6cHJpdnk6Y20xeXoyaWswMDB1ZmYyNmZuNmlnbzQzcCIsInJvbGUiOiJBRE1JTiIsImlzcyI6ImhlYWx0aGJsb2Nrcy5pbyAifQ.agSAmkcDesHSRxAuj4eg0Kg_5E0nPR0Y_WcW78pqAH0' } });
                }
                setProgressMessage(`Uploaded ${users.length} Users`);
            }
        }
        catch (err) {
            addErrorMessage(`Unexpected Error exporting users ${JSON.stringify(err)}`);
        }
    }

    const get_weight = (userProfile) => {
        return userProfile.weight.system === "IMPERIAL" ? { st: userProfile.weight.st, lb: userProfile.weight.lb, system: userProfile.weight.system } : { kg: userProfile.weight.kg, system: userProfile.weight.system }
    }

    const get_height = (userProfile) => {
        return userProfile.height.system === "IMPERIAL" ? { ft: userProfile.height.ft ? userProfile.height.ft : 0, in: userProfile.height.in ? userProfile.height.in : 0, system: userProfile.height.system } : { cm: userProfile.height.cm, system: userProfile.height.system }
    }

    const exportUserProfiles = async (e) => {
        if (e) e.preventDefault();
        setErrorMessages([]);
        try {
            setProgressMessage("Checking Local API on port 8000 ...");
            var response = await axios.get('http://localhost:8000/api-status')
            if (response.status != 204) {
                setProgressMessage("No API found on port 8000");
                return;
            }
            else {
                setProgressMessage("Fetching User Profiles from Healthblocks API ...");
                var userProfileResponse = await UserProfileApi.get("/user-profile/");
                if (userProfileResponse.status != 200) {
                    setProgressMessage(`Error Calling Healthblocks User API. - Response Status ${userProfileResponse.status}`);
                    return;
                }
                var userProfiles = userProfileResponse.data.data;
                setProgressMessage(`Found ${userProfiles.length} User Profiles ... Uploading to Local API port 8000 ...`);
                for (var userProfile of userProfiles) {
                    setProgressMessage(`Uploading user profile ${userProfile.nickname} ${userProfile.lastName} ...`);
                    var newUserProfile = {
                        weFitterPublicId: userProfile.weFitterPublicId,
                        weFitterProfileToken: userProfile.weFitterProfileToken,
                        preferedName: userProfile.preferedName,
                        lastName: userProfile.lastName,
                        nickname: userProfile.nickname,
                        avatar: userProfile.avatar,
                        dateOfBirth: userProfile.dateOfBirth,
                        biologicalSex: userProfile.biologicalSex,
                        gender: userProfile.gender,
                        countryCode: userProfile.countryCode,
                        zipCode: userProfile.zipCode,
                        town: userProfile.town,
                        profileImageUrl: userProfile.profileImageUrl,
                        ethnicity: userProfile.ethnicity,
                        mode: userProfile.mode
                    }
                    try {
                        newUserProfile.weight = get_weight(userProfile);
                        newUserProfile.height = get_height(userProfile);
                        var addResponse = await axios.put(`http://localhost:8000/user-profile/${userProfile.userId}`, newUserProfile, { headers: { 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6cHJpdnk6Y20xeXoyaWswMDB1ZmYyNmZuNmlnbzQzcCIsInJvbGUiOiJBRE1JTiIsImlzcyI6ImhlYWx0aGJsb2Nrcy5pbyAifQ.agSAmkcDesHSRxAuj4eg0Kg_5E0nPR0Y_WcW78pqAH0' } });
                    }
                    catch (uploadErr) {
                        setErrorMessages(`Unexpected Error Uploading User Profile For ${userProfile.userId}`);
                    }
                }
                setProgressMessage(`Uploaded ${userProfiles.length} User Profiles`);
            }
        }
        catch (err) {
            addErrorMessage(`Unexpected Error exporting user profiles ${JSON.stringify(err)}`);
        }
    }

    return (
        <div>
            <div class="space-y-12">
                <div class="border-b border-gray-900/10 pb-12">
                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                        <div class="sm:col-span-6 bg-black text-white px-2">
                            <h2 class="text-base font-semibold leading-7">Developer Tools</h2>
                        </div>

                        <div class="sm:col-span-6">
                            <Link to="#" onClick={(e) => exportUsers(e)} class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Export Users</Link>
                        </div>
                        <div class="sm:col-span-6">
                            <Link to="#" onClick={(e) => exportUserProfiles(e)} class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Export User Profiles</Link>
                        </div>

                    </div>
                </div>
                {
                    progressMessage && progressMessage.length > 0
                        ?
                        <section class="mx-4" id="progressMessages">
                            <DisplayProgressMessage progressMessage={progressMessage} />
                        </section>
                        :
                        <></>
                }
                {
                    errorMessages && errorMessages.length > 0
                        ?
                        <section class="mx-4" id="errorMessages">
                            <DisplayErrorMessages errorMessages={errorMessages} />
                        </section>
                        :
                        <></>
                }

            </div>

        </div>
    );
};

export default DeveloperTools;