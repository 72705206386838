import React, { useState } from 'react';
import { ActivityPoolApi, PaymentApi } from '../ApiConfig';
import { getAccessToken } from '@privy-io/react-auth';

const EnterActivityPoolButton = (props) => {

    var activityPool = props.activityPool;
    var setRefreshRegistration = props.setRefreshRegistration;
    const [progressMsg, setProgressMsg] = useState();

    const enterPool = async () => {
        try {

            if (!activityPool) {
                setProgressMsg('No activityPoolId supplied to EnterActivityPool');
            }
            else {
                if (activityPool.entryFee == 0) {
                    //No payment Required - Just add to registrations
                    if (activityPool.activityPoolStatus == "OPEN") {
                        try {
                            setProgressMsg('adding user to registration ...');
                            var result = await ActivityPoolApi.post(`/registrations/?activity_pool_id=${activityPool.activityPoolId}`);
                            setRefreshRegistration(true);
                        }
                        catch(err) {
                            if(err.status === 422) {
                                setProgressMsg(err.detail);
                            }
                            else {
                                setProgressMsg(err.detail ? err.detail : "Unexpected error entering free activity pool " + err.message);
                            }
                        }
                    }
                    else {
                        debugger;
                        setProgressMsg(`Unable to register user ... invalid ap status (${activityPool.activityPoolStatus})`);
                    }
                }
                else {
                    try {
                        //Need to pay to enter 
                        const baseUrl = window.location.origin;
                        var paymentRequest = {
                            "product": {
                                "type": "ACTIVITY_POOL_ENTRY",
                                "activityPoolId": activityPool.activityPoolId
                            },
                            "returnUrl": `https://everest.healthblocks.ai/paymentcomplete`
                        };
                        var token = await getAccessToken();
                        setProgressMsg('Requesting payment ... please wait');
                        var response = await PaymentApi.post('/transactions/', paymentRequest);
                        var makePaymentUrl = response.data.redirect_url;
                        setProgressMsg(`Redirecting to payment (${makePaymentUrl})... please wait`);
                        window.location.href = makePaymentUrl;
                    }
                    catch(err) {
                        setProgressMsg(err.response && err.response.data && err.response.data.detail ? err.response.data.detail : err.message);
                    }
                }
            }

        }
        catch (err) {
            setProgressMsg(`Unexpected error entering activity pool - ${err.message}`);
        }
    };

    return (
            activityPool.activityPoolStatus === "OPEN"
                ?
                <section>
                    <div class="sm:col-span-12 bg-white text-black px-2 items-right">
                        <button type="button" onClick={() => enterPool()} class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Enter Pool</button>
                    </div>
                    <div class="sm:col-span-12 bg-white text-black px-2 items-right">
                        {
                            progressMsg
                                ?
                                <div>
                                    <span class="block text-xs font-medium leading-6 text-gray-400">{progressMsg}</span>
                                </div >
                                :
                                <span></span>
                        }
                    </div>

                </section >
                :
                <section>Unable to Join this AP</section>


    );
};

export default EnterActivityPoolButton;