import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { PaymentApi } from '../ApiConfig';

const SponsorPrizeForm = () => {
    const { activityPoolId } = useParams();
    const [sponsorPrize, setSponsorPrize] = useState({
        activityPoolId: '',
        currencyCode: 'XXX',
        description: '',
        amount: 0,
        transactionDate: '',
        transactionId: ''
    });

    useEffect(() => {
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSponsorPrize({ ...sponsorPrize, [name]: value });
    };

    const triggerPayment = async () => {
        var paymentRequest = {
            activityPoolId: activityPoolId,
            returnUrl: '/paymentcomplete/txid'
        };
        const response = await PaymentApi.get('/transactions/', paymentRequest);
    };

    return (
        <div>
            <div>
                <div class="space-y-12">
                    <div class="border-b border-gray-900/10 pb-12">
                        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                            <div class="sm:col-span-6 bg-black text-white px-2">
                                <h2 class="text-base font-semibold leading-7">New Sponsor Prize for ACTIVITY POOL</h2>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="description" class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                                <div class="mt-2">
                                    <input name="description" value={sponsorPrize.description} onChange={handleChange} placeholder="Description" required id="description" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>

                            <div class="sm:col-span-1">
                                <label for="amount" class="block text-sm font-medium leading-6 text-gray-900">Amount ({sponsorPrize.currencyCode})</label>
                                <div class="mt-2">
                                    <input type="text" name="amount" value={sponsorPrize.amount} onChange={handleChange} placeholder="Amount" required id="amount" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>

                            <div class="sm:col-span-1">
                                <div class="mt-8">
                                    <Link onClick={() => { triggerPayment() }} class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="">Add Prize</Link>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SponsorPrizeForm;