import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { getAccessToken } from '@privy-io/react-auth';

const HealthDataList = (props) => {
    const [healthdata, setHealthData] = useState([]);

    const userId = props.userId;

    const fetchHealthData = async () => {
        var token = await getAccessToken();
        let config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
        var endDate = moment().format("YYYY-MM-DD");
        var startDate = moment().subtract(60, 'd').format("YYYY-MM-DD")
        var apiUrl = process.env.REACT_APP_HEALTH_DATA_API_URL ? process.env.REACT_APP_HEALTH_DATA_API_URL : "https://immensely-just-seasnail.ngrok-free.app";
        var path = `${apiUrl}/formatted${props.userId ? "?user_id=" + props.userId + "&start_date=" + startDate + "&end_date=" + endDate : "?start_date=" + startDate + "&end_date=" + endDate}`;
        const response = await axios.get(path, config);
        var results = [];
        var weekNum = 0;
        response.data.forEach(week => {
            results = results.concat(week[weekNum++]);
        });
        results = results.sort((a, b) => new Date(b.date) - new Date(a.date));
        setHealthData(results);
    };

    var useEffectHasRun = false;
    useEffect(() => {
        if (!useEffectHasRun) {
            useEffectHasRun = true;
            fetchHealthData();
        }
    }, []);

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">{healthdata.length} Health Records</h1>
                </div>
            </div>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Day</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Steps</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Calories</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Run Distance</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Cycling Distance</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {healthdata
                                    .map(healthDataRecord => (
                                        <tr class="divide-x divide-gray-200">
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{healthDataRecord.tag}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.steps}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.calories}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.running_distance}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.cycling_distance}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.date}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HealthDataList;