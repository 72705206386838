import React, { useEffect, useState } from 'react';
import { PaymentApi } from '../ApiConfig';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { use } from 'react';

const AccountList = (props) => {
    const [accountList, setAccountList] = useState([]);
    const [balances, setBalances] = useState([]);
    const [currencyCode, setCurrencyCode] = useState([]);
    const user_id = props.user_id;
    const activityPoolSponsor = props.activity_pool_sponsor;
    const activityPool = props.activity_pool;
    const healthblocks = props.healthblocks;

    const fetchAccount = async (currency_code) => {
        try {
            const response = await PaymentApi.get(`/account-records/?user_id=${user_id}&currency_code=${currency_code}`);
            if (Array.isArray(response.data.data)) {
                setAccountList(response.data.data);
            }
            else {
                setAccountList([]);
            }
        }
        catch (err) {
            console.log(`Unexpected error list account record - ${err}`);
            setAccountList([]);
        }
    };



    const fetchBalances = async () => {
        try {
            const response = await PaymentApi.get(
                `/wallet/summary/?user_id=${user_id}`
            );
            const balances = response.data.data;
            setBalances(balances);
            if (balances.length > 0) {
                fetchAccount(balances[0].currencyCode);
            }
        } catch (err) {
            console.log(`Error fetching balance: ${err}`);
        }
    };

    useEffect(() => {
        if(user_id) {
            fetchBalances()
            fetchAccount();
        }
    }, [user_id]);

    useEffect(() => {
        const fetchActivityPoolAccount = async (currency_code) => {
            try {
                const response = await PaymentApi.get(`/account-records/activity-pools/?activity_pool_id=${activityPool.activityPoolId}`);
                if (Array.isArray(response.data.data)) {
                    setAccountList(response.data.data);
                }
                else {
                    setAccountList([]);
                }
            }
            catch (err) {
                console.log(`Unexpected error list account record - ${err}`);
                setAccountList([]);
            }
        };
        if(activityPool) {
            fetchActivityPoolAccount(activityPool.currencyCode)
        }
    }, [activityPool]);

    const handleChange = (e) => {
        const { value } = e.target;
        if (value) {
            fetchAccount(value);
        }
    };

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            {
                user_id
                    ?
                    <div class="flex flex-col sm:flex-row sm:space-x-4">
                        <div class="flex flex-col mb-4 sm:mb-0">
                            <label for="currency_filter" class="block text-sm font-medium leading-6 text-gray-900">Account Balances</label>
                            <select
                                id="currency_filter"
                                name="currency_filter"
                                onChange={(e) => handleChange(e)}
                                placeholder="Currency Code"
                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                                {balances.map(balance => (
                                    <option value={balance.currencyCode}>{`${balance.currencyCode} ${balance.balance}`}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    :
                    activityPool
                        ?
                        <h1>Currency: {activityPool.currencyCode}</h1>
                        :
                        activityPoolSponsor
                            ?
                            <h1>activityPoolSponsor.id</h1>
                            :
                            <h1>Healthblocks</h1>
            }

            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Date</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Description</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">DR</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">CR</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {accountList
                                    .map(accountItem => (
                                        <tr class="divide-x divide-gray-200">
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{moment(accountItem.createdAt).format('DD/MM/YYYY')}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{accountItem.activityTitle ? accountItem.activityTitle : accountItem.type}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500 text-right">{accountItem.debit ? accountItem.debit : accountItem.amount < 0 ? (accountItem.amount * -1).toFixed(2): ''}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500 text-right">{accountItem.credit ? accountItem.credit : accountItem.amount > 0 ? accountItem.amount.toFixed(2) : ''}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountList;