import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ConfirmActionPopup = (props) => {
    var dialogTitle = props.dialog_title ? props.dialog_title : "Confirm Action";
    var showPopup = props.show_popup;
    var setShowPopup = props.set_show_popup;
    var setResponse = props.set_response;

    const [isModalOpen, setIsModalOpen] = useState(false);


    const setDialogResponse = (e, value) => {
        //Validate inputs
        e.preventDefault();
        //Tell Parent to close the popup
        setShowPopup(false);
        setResponse(e, value);
    }

    useEffect(() => {
        setIsModalOpen(showPopup);
    }, [showPopup]);

    return (
        <div>
            {isModalOpen && (
                <div class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                    <div class="space-y-12 bg-white rounded-lg shadow-lg p-6 xs:w-full sm:w-1/2">
                        <div class="sm:flex sm:items-center">
                            <div class="sm:flex-auto">
                                <h1 class="text-base font-semibold leading-6 text-gray-900">{dialogTitle}</h1>
                            </div>
                        </div>
                        <div class="mt-6 flex items-center justify-end gap-x-6">
                            <Link onClick={(e) => setDialogResponse(e, true)} to="#" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >Yes</Link>
                            <Link onClick={(e) => setDialogResponse(e, false)} to="#" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >Cancel</Link>
                        </div>

                    </div>

                </div>
            )}
        </div>
    );
}

export default ConfirmActionPopup;
