import { usePrivy, useLogin, getAccessToken, useLogout } from '@privy-io/react-auth';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UserApi } from '../ApiConfig';


export function LoginForm(props) {
    const [tokenValue, setTokenValue] = useState('TOKEN NOT SET');
    const { ready, authenticated } = usePrivy();
    const loggedIn = !ready || (ready && authenticated);
    const [healthblocksUser, setHealthblocksUser] = useState();
    const setIsAdminUser = props.set_is_admin_user;

    //Inform Parent What the user is
    useEffect(() => {
        if (setHealthblocksUser) setHealthblocksUser(healthblocksUser);
    }, [healthblocksUser]);

    return (
        <div id="loginButtons" class="float-right mr-2 mt-2 text-sm">
            {!loggedIn
                ?
                <LoginButton />
                :
                <LogoutButton set_is_admin_user={setIsAdminUser} set_user={setHealthblocksUser} user={healthblocksUser} />
            }
        </div>
    );
}

function LoginButton(props) {
    const navigate = useNavigate();

    const { logout } = useLogout({
        onSuccess: () => {
            console.log('User logged out');
            if (props.setTokenValue) props.setTokenValue("LOGGED OUT");
        },
    });

    const { login } = useLogin({
        onComplete: async (user, isNewUser, wasAlreadyAuthenticated, loginMethod, linkedAccount) => {
            console.log(user, isNewUser, wasAlreadyAuthenticated, loginMethod, linkedAccount);
            // Any logic you'd like to execute if the user is/becomes authenticated while this
            // component is mounted
            const accessToken = await getAccessToken();
            props.setTokenValue(accessToken);
            navigate("/users");
        },
        onError: (error) => {
            console.log(error);
            // Any logic you'd like to execute after a user exits the login flow or there is an error
        },
    });

    const { ready, authenticated } = usePrivy();
    // Disable login when Privy is not ready or the user is already authenticated
    const disableLogin = !ready || (ready && authenticated);

    return (
        !disableLogin
            ?
            <button type="button" onClick={login} class="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <span class="absolute -inset-1.5"></span>
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full" src="anonymoususer.jpeg" alt=""></img>
            </button>
            :
            ""
    );
}

function LogoutButton(props) {
    const { ready, authenticated, user } = usePrivy();
    const navigate = useNavigate();
    const hbUser = props.user;
    const setHealthblocksUser = props.set_user;
    const setIsAdminUser = props.set_is_admin_user;

    const { logout } = useLogout({
        onSuccess: () => {
            console.log('User logged out');
            setHealthblocksUser(null);
            if(setIsAdminUser) setIsAdminUser(false);
            if (props.setTokenValue) props.setTokenValue("LOGGED OUT");
            navigate("/");
        },
    });

    // Disable logout when Privy is not ready or the user is not authenticated
    const disableLogout = !ready || (ready && !authenticated);

    const fetchUser = async (userId) => {
        try {
            const response = await UserApi.get(`/users/${userId}`);
            const hbUser = response.data;
            if (hbUser.role !== "ADMIN") logout();
            else {
                setHealthblocksUser(hbUser);
                if(setIsAdminUser) setIsAdminUser(true);
            }
        }
        catch (err) {
            console.log(JSON.stringify(err));
            logout();
        }
    }

    useEffect(() => {
        if (!disableLogout) fetchUser(user.id);
    }, [disableLogout]);

    return (
        !disableLogout
            ?
            <>
                <button type="button" onClick={logout} class="x relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                    <span class="absolute -inset-1.5"></span>
                    <span class="sr-only">Open user menu</span>
                    <img class="h-8 w-8 rounded-full" src="logout.jpeg" alt=""></img>
                </button>
                <p class="text-white text-xs">{hbUser ? hbUser.email : "Login"}</p>
            </>
            :
            ""
    );
}

export default LoginForm


