import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DisplayProgressMessage from './DisplayProgressMessage';

const AddSponsorPrizePopup = (props) => {
    var showPopup = props.show_popup;
    var setShowPopup = props.set_show_popup;
    var setSponsorPrize = props.set_sponsor_prize;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [prizeDetails, setPrizeDetails] = useState({
        amount: 0,
        description: ""
    });
    const [progressMessage, setProgressMessage] = useState(false);

    const closeModal = (e) => {
        if (e) e.preventDefault();
        //Tell Parent to close the popup
        setShowPopup(false);
    };

    const savePrizeDetails = (e) => {
        //Validate inputs
        if (e) e.preventDefault();
        const amt = Number(prizeDetails.amount);
        if (!Number.isInteger(amt) || amt <= 0) {
            //Invalid Amount
            setProgressMessage(`Amount ${prizeDetails.amount} must be whole number and greater than 10`);
        }
        else if (prizeDetails.description.length < 10) {
            setProgressMessage("Description must be 10 or more characters long");
        }
        else {
            //Send Prize Details to Parent
            setSponsorPrize(prizeDetails);
            //Tell Parent to close the popup
            setShowPopup(false);
        }
    }

    useEffect(() => {
        if(showPopup) setProgressMessage("");
        setIsModalOpen(showPopup);
    }, [showPopup]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPrizeDetails({ ...prizeDetails, [name]: value });
    };


    return (
        <div>
            {isModalOpen && (
                <div class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                    <div class="space-y-12 bg-white rounded-lg shadow-lg p-6 xs:w-full sm:w-1/2">
                        <div class="border-b border-gray-900/10 pb-12">
                            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                <div class="sm:col-span-6 bg-black text-white px-2">
                                    <h2 class="text-base font-semibold leading-7">Enter Bonus Prize Details</h2>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="amount" class="block text-sm font-medium leading-6 text-gray-900">Amount</label>
                                    <div class="mt-2">
                                        <input type="text" name="amount" value={prizeDetails.amount} onChange={handleChange} placeholder="Amount" required id="amount" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="description" class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                                    <div class="mt-2">
                                        <input name="description" value={prizeDetails.description} onChange={handleChange} placeholder="Description" required id="currencyName" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-6 flex items-center justify-end gap-x-6">
                            <Link to="" onClick={(e) => closeModal(e)} class="text-sm font-semibold leading-6 text-gray-900">Cancel</Link>
                            <Link to="" onClick={(e) => savePrizeDetails(e)} class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Continue</Link>
                        </div>
                        <section class="mx-4" id="progressMessages">
                            <DisplayProgressMessage progressMessage={progressMessage} />
                        </section>
                    </div>

                </div>
            )}
        </div>
    );
}

export default AddSponsorPrizePopup;
