import React, { useEffect, useState } from 'react';
import { UserApi } from '../ApiConfig';
import { Link } from 'react-router-dom';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [emailFilter, setEmailFilter] = useState();
    const [lastNameFilter, setLastNameFilter] = useState();

    const fetchUsers = async () => {
        const criteria = [];
        if (emailFilter) criteria.push(`email=${emailFilter}`);
        if (lastNameFilter) criteria.push(`last_name=${lastNameFilter}`);
        const queryString = criteria.length > 0 ? '?' + criteria.join('&') : ''
        const response = await UserApi.get(`/users/filtered/${queryString}`);
        setUsers(response.data.data);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [emailFilter, lastNameFilter]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "email_filter") {
            setEmailFilter(value);
        }
        else if (name === "last_name_filter") {
            setLastNameFilter(value);
        }
    };

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="flex flex-col sm:flex-row sm:space-x-4">
                <div class="flex flex-col mb-4 sm:mb-0">
                    <label for="email_filter" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                    <input name="email_filter" value={emailFilter} onChange={handleChange} placeholder="Email Filter" id="email_filter" class="p-1 block w-full sm:w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                </div>
                <div class="flex flex-col">
                    <label for="last_name_filter" class="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                    <input name="last_name_filter" value={lastNameFilter} onChange={handleChange} placeholder="Last Name Filter" id="last_name_filter" class="p-1 block w-full sm:w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                </div>
            </div>

            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle">
                        <table class="min-w-full border-separate border-spacing-0">
                            <thead>
                                <tr>
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                                        <span class="sr-only">Edit</span>
                                    </th>
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">Email</th>
                                    <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter md:table-cell">Phone</th>
                                    <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">Role</th>
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter  hidden lg:table-cell">Date Added</th>
                                    <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter md:table-cell">User Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user => (
                                    <tr key={user.id}>
                                        <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8">
                                            <Link class="text-indigo-600 hover:text-indigo-900" to={`/users/${user.userId}`}>Edit</Link>
                                        </td>
                                        <td class="whitespace-nowrap border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">{user.email}</td>
                                        <td class="hidden whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 md:table-cell">{user.phone}</td>
                                        <td class="hidden whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 sm:table-cell">{user.role}</td>
                                        <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500  hidden lg:table-cell">{user.signUpDate}</td>
                                        <td class="hidden whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 md:table-cell">{user.userId}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserList;