import React, { useEffect, useState } from 'react';
import { ActivityPoolApi, UserProfileApi } from '../ApiConfig';
import { Link } from 'react-router-dom';
import EnterActivityPoolButton from './EnterActivityPoolButton';
import DisplayProgressMessage from './DisplayProgressMessage';
import DisplayErrorMessages from './DisplayErrorMessages';
import ConfirmActionPopup from './ConfirmActionPopup';

const ActivityPoolRegistrations = (props) => {
    const [apRegistrations, setApRegistrations] = useState([]);
    const activityPool = props.activityPool;
    const activityPoolId = activityPool.activityPoolId;
    const [refreshRegistrations, setRefreshRegistration] = useState(false);
    const [userProfile, setUserProfile] = useState();

    const [progressMessage, setProgressMessage] = useState();
    const [errorMessages, setErrorMessages] = useState([]);
    const [currRegistration, setCurrentRegistration] = useState();

    const addErrorMessage = (newError) => { setErrorMessages([...errorMessages, newError]) };    

    const [showPopup, setShowPopup] = useState(false);

    const fetchAPRegistrations = async () => {
        try {
            const response = await ActivityPoolApi.get(`/activity-pools/${activityPoolId}/registrations/`);
            setApRegistrations(response.data.data);
            setRefreshRegistration(false);
        }
        catch (err) {
            console.error(err);
            addErrorMessage(`Unable to retrieve registrations - ${err.detail}`);
        }
    };

    const handlePopupResponse = async (e, value) => {
        e.preventDefault();
        setShowPopup(false);
        if(value) {
            try {
                setProgressMessage(`Unregistering user ${currRegistration.userId} ...`);
                await ActivityPoolApi.delete(`/activity-pools/registrations/?activity_pool_id=${currRegistration.activityPoolId}&user_id=${currRegistration.userId}`);
                setProgressMessage(`User ${currRegistration.userId} UnRegistered`);
                await fetchAPRegistrations();
            }
            catch (err) {
                console.error(err);
                if(err.status === 422) {
                    //Request was rejected by the server
                    addErrorMessage(`Unable to unregister user - ${err.detail}`);
                }
                else {
                    addErrorMessage(`Unable to unregister user - ${err.detail ? err.detail : JSON.stringify(err)}`);
                }
            }
        }
    }

    const deleteAPRegistration = async (e, registration) => {
        e.preventDefault();
        setCurrentRegistration(registration);
        setShowPopup(true);
    };

    const showRegistrationUserDetails = async (registration) => {
        try {
            const userId = registration.userId;
            const response = await UserProfileApi.get(`/user-profile/${userId}`);
            if (response && response.data) {
                setUserProfile(response.data)
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchAPRegistrations();
    }, []);

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">{apRegistrations.length} Pool Registrations</h1>
                </div>
            </div>

            {
                activityPoolId
                    ?
                    <div class="mt-6 flex items-center gap-x-6">
                        <EnterActivityPoolButton activityPool={activityPool} setRefreshRegistration={setRefreshRegistration} />
                    </div>
                    :
                    <span></span>
            }

            <div className="rounded-md bg-blue-50 p-4 mt-4">
                <div className="flex">
                    <div className="ml-3 flex-1 md:flex md:justify-between">

                        { userProfile ? <p className="text-sm text-blue-700">{`${userProfile.preferedName} ${userProfile.lastName} (${userProfile.userId})`}</p> : <p className="text-sm text-blue-700">Hover over user Id to see name</p> }
                    </div>
                </div>
            </div>
            

            <div className="rounded-md p-4 mt-4">
                <div className="flex">
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                        <DisplayProgressMessage progressMessage={progressMessage}  />
                        <DisplayErrorMessages errorMessages={errorMessages}/>
                    </div>
                </div>
            </div>

            <ConfirmActionPopup dialog_title="Are you sure you want to unregister this user?" show_popup={showPopup} set_show_popup={setShowPopup} set_response={handlePopupResponse} />

            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">User</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {apRegistrations
                                    .map(APRegistration => (
                                        <tr key={APRegistration.APRegistrationCode} class="divide-x divide-gray-200">
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                                                <Link key={APRegistration.userId} onMouseLeave={() => setUserProfile(null)} onMouseOver={() => showRegistrationUserDetails(APRegistration)} to={`/users/${APRegistration.userId}`} class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >{APRegistration.userId}</Link>
                                            </td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{APRegistration.status}</td>
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                                                <Link key={`DEL-${APRegistration.userId}`} onClick={(e) => deleteAPRegistration(e, APRegistration)} to="" class="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 ml-2" >Delete</Link>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ActivityPoolRegistrations;