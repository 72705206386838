import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HexColorPicker } from 'react-colorful';


const ColorPicker = (props) => {
    return (
        <HexColorPicker color={props.color} onChange={props.setColor} />
    );
};

export default ColorPicker;