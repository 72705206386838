import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { getAccessToken } from '@privy-io/react-auth';

const IntelliproveDataDiagnosticsList = (props) => {
    const [healthdata, setHealthData] = useState([]);

    const fetchHealthData = async () => {
        try {
            var token = await getAccessToken();
            let config = {
                headers: {
                    "ngrok-skip-browser-warning": "69420",
                    "Authorization": `Bearer ${token}`
                }
            }
            var endDate = moment().format("YYYY-MM-DD");
            var startDate = moment().subtract(60, 'd').format("YYYY-MM-DD")
            var apiUrl = process.env.REACT_APP_HEALTH_DATA_API_URL ? process.env.REACT_APP_HEALTH_DATA_API_URL : "https://immensely-just-seasnail.ngrok-free.app";
            var path = `${apiUrl}${props.userId ? "?user_id="+props.userId+"&start_date="+startDate+"&end_date="+endDate : "?start_date="+startDate+"&end_date="+endDate}`;
            const response = await axios.get(path, config);
            if (response.data && Array.isArray(response.data)) setHealthData(response.data);
            else {
                console.log(response.data);
            }
        }
        catch (err) {
            console.log(`Error Fetching Diagnostic Intelliprove Data Id for ${props.userId}`);
        }
    };

    var useEffectHasRun = false;
    useEffect(() => {
        if (!useEffectHasRun) {
            useEffectHasRun = true;
            fetchHealthData();
        }
    }, []);

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">{healthdata.length} Diagnostic Health Records</h1>
                </div>
                <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <Link onClick={() => { fetchHealthData() }} class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="">Refresh</Link>
                </div>

                <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                </div>
            </div>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Date</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">ANS</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Breathing</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Heart Rate</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">HRV</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Mental Health</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Readiness</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Respiratory</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Stress</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {healthdata
                                    .map(healthDataRecord => (
                                        <tr class="divide-x divide-gray-200">
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{moment(healthDataRecord.activity_date).format("DD-MM-YYYY")}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.ans}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.breathing}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.heart_rate}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.hrv}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.mental_health}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.readiness}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.respiratory}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.stress}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntelliproveDataDiagnosticsList;