import React, { useEffect, useState, useRef } from 'react';
import { ActivityPoolApi } from '../ApiConfig';
import { Link } from 'react-router-dom';
import moment from 'moment';

const ActivityPoolList = (props) => {
    const hasRun = useRef(false);
    const [activityPools, setActivityPools] = useState([]);

    const apSponsor = props.sponsor;
    const user_id = props.user_id

    const fetchActivityPools = async (params) => {
        var apiUrl = '/activity-pools/filtered/';
        var apiParams = [];
        if (apSponsor) apiParams.push(`activity_pool_sponsor_id=${apSponsor.sponsorId}`);
        if (user_id) {
            apiParams.push(`user_id=${user_id}`);
            apiParams.push(`my_pools_only=true`);
        }
        if (params && params.apstatus && params.apstatus !== "") apiParams.push(`activity_pool_status=${params.apstatus}`);
        var apiParamsStr = "";
        apiParams.forEach(apiParam => {
            apiParamsStr = apiParamsStr === "" ? `?${apiParam}` : apiParamsStr + `&${apiParam}`;
        });
        var response = await ActivityPoolApi.get(`${apiUrl}${apiParamsStr}`);
        setActivityPools(response.data.data);
    };

    useEffect(() => {
        if (!hasRun.current) {
            hasRun.current = true;
            fetchActivityPools();
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "apstatus") {
            fetchActivityPools({ apstatus: value });
        }
    };

    const get_currency_description = (value, currency_code) => {
        if (currency_code === "EUR") return `€${value}`;
        else if (currency_code === "USD") return `$${value}`;
        else if (currency_code === "GBP") return `£${value}`;
        else return `${value} ${currency_code}`;
    }

    const get_reward_mechanism_description = (activity_pool) => {
        if (activity_pool.rewardMechanism === 'EQUAL') return '';
        else if (activity_pool.rewardMechanism === 'LOTTERY') return `Max (${activity_pool.rewardMaxWinners} Winners)`;
        else if (activity_pool.rewardMechanism === 'FIXED') return `Fixed - ${get_currency_description(activity_pool.rewardFixedAmount, activity_pool.currencyCode)}`;
    }

    const get_boolean_text = (value) => {
        return value ? 'Yes' : '';
    }

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">{activityPools.length} Activity Pools {apSponsor ? "For " + apSponsor.name : ""}</h1>
                    <div class="sm:col-span-1 mt-4">
                        <label for="apstatus" class="block text-sm font-medium leading-6 text-gray-900">AP Status</label>
                        <div class="mt-2">
                            <select id="apstatus" name="apstatus" onChange={(e) => handleChange(e)} placeholder="AP Status" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                <option></option>
                                <option>DRAFT</option>
                                <option>REVIEW</option>
                                <option>PUBLISHED</option>
                                <option>OPEN</option>
                                <option>ACTIVE</option>
                                <option>FINISHED</option>
                                <option>COMPLETED</option>
                            </select>
                        </div>
                    </div>

                </div>
                {
                    user_id
                        ?
                        <></>
                        :
                        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex">
                            <Link to="#" onClick={(e) => fetchActivityPools()} class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mr-2" >Refresh</Link>
                            <Link to={`/activitypools/new${apSponsor ? "/" + apSponsor.sponsorId : ""}`} class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >{`New ${apSponsor ? "Sponsored" : ""} Activity Pool`}</Link>
                        </div>
                }

            </div>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                                        <span class="sr-only">Edit</span>
                                    </th>
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Status</th>
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Title</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Reward Type</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Entry Fee</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Start</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Goal</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Prize Fund</th>
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">Sponsored</th>
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">Private</th>
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">Data Pool</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {activityPools
                                    .map(activityPool => (
                                        <tr key={activityPool.activityPoolId} class="divide-x divide-gray-200">
                                            <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-2 lg:pr-2">
                                                <Link class="text-indigo-600 hover:text-indigo-900" to={`/activitypool/${activityPool.activityPoolId}`}>Edit</Link>
                                            </td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500">{activityPool.activityPoolStatus}</td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500">{activityPool.title}</td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500 text-right">{get_reward_mechanism_description(activityPool)}</td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500 text-right">{get_currency_description(activityPool.entryFee, activityPool.currencyCode)}</td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500">{moment(activityPool.startDateTime).format('DD/MM/YYYY')}</td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500">{activityPool.goal + ' ' + activityPool.goalType + ' (' + activityPool.cheatDays + ' Cheat Days)'}</td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500 text-right">{`${get_currency_description(activityPool.prizeFund, activityPool.currencyCode)}`}</td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500">{activityPool.sponsorId && activityPool.sponsorId != "string" ? `Yes` : ""}</td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500">{get_boolean_text(activityPool.privatePool)}</td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500">{activityPool.dataPoolId ? `Yes` : ""}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivityPoolList;